
/* --- img.max-height-SIZE (when an img directly has this class set on it) --- */

img.max-height-300 {
  max-height: 300px; }
img.max-height-350 {
  max-height: 350px; }
img.max-height-400 {
  max-height: 400px; }
img.max-height-450 {
  max-height: 450px; }
img.max-height-500 {
  max-height: 500px; }
img.max-height-550 {
  max-height: 550px; }
img.max-height-600 {
  max-height: 600px; }
img.max-height-650 {
  max-height: 650px; }
img.max-height-700 {
  max-height: 700px; }
img.max-height-800 {
  max-height: 800px; }


/* --- .max-height-SIZE img (when an img is a DESC of .max-height class) --- */

.max-height-300 img {
  max-height: 300px; }
.max-height-350 img {
  max-height: 350px; }
.max-height-400 img {
  max-height: 400px; }
.max-height-450 img {
  max-height: 450px; }
.max-height-500 img {
  max-height: 500px; }
.max-height-550 img {
  max-height: 550px; }
.max-height-600 img {
  max-height: 600px; }
.max-height-650 img {
  max-height: 650px; }
.max-height-700 img {
  max-height: 700px; }
.max-height-800 img {
  max-height: 800px; }


/* --- .fig: used for figure handling --- */
// type I (NP) came up with which embedded images end
// up using, via `layouts/_default/_markup/render-image.html


.image_featured {
  max-height: 500px; }

.fig {
  display: block;
  margin-left: auto !important;
  margin-right: auto !important;
  text-align: center; }

.fig.left {
  display: block;
  float: left;
  padding: 10px;
  padding-left: 0;
  padding-right: 20px; }

.fig.right {
  display: block;
  float: right;
  padding: 10px;
  padding-left: 20px;
  padding-right: 0; }
