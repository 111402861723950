.side-toc {
  overflow-y: hidden;
  overflow-y: auto;

  position: fixed !important;
  top: 160px;

  font-size: 15px;
  transition: all 300ms ease-in-out;

  height: 100%;
  max-width: 260px;
  //width: 280px;
  transform: translateX(0);

  > .toc-list {
    overflow: hidden;
    position: relative;

    li {
      list-style: none;
    }
  }

  // ---

  .toc-list {
    margin: 0;
    padding-left: 10px;
  }

  a.toc-link {
    color: currentColor;
    height: 100%;

    // page
    text-decoration: none;
  }
  .is-collapsible {
    max-height: 1000px;
    overflow: hidden;
    transition: all 300ms ease-in-out;
  }
  .is-collapsed {
    max-height: 0;
  }
  .is-active-link {
    font-weight: 700;
  }
  .toc-link::before {
    background-color: #eee;
    content: " ";
    display: inline-block;
    height: inherit;
    left: 0;
    margin-top: -1px;
    position: absolute;
    width: 2px;
  }
  .is-active-link::before {
    background-color: var(--theme); // purple; //#54bc4b;
  }
}

//?
#toc:checked ~ .side-toc {
  box-shadow: 0 0 5px #c8c8c8;
  transform: translateX(0);
}
