
code {
  &.noClass {
    --inlineColor: rgb(66, 66, 66);
    color: var(--inlineColor); } }

mjx-math {
  font-size: 85% !important; }

// --- Los Alamos ---

div.los-alamos-series-header {
  padding: 10px;
  padding-left: 25px;
  margin: 10px auto;
  background-color: var(--aside);

  p {
    font-size: 15px;
    font-style: italic;
    padding-top: 2px;
    padding-bottom: 2px; }

  // .burb
  //   color: yellow
  // .authors
 }  //   color: purple

// --- cptqa ---

div.cpt-series-header {
  padding: 10px;
  padding-left: 25px;
  margin: 10px auto;
  color: #7a7a7a;
  background-color: #dbdbdb;

  p {
    font-size: 15px;
    font-style: italic;
    padding-top: 5px;
    padding-bottom: 5px; } }

.cpt-question {
  font-style: normal; } //italic

// ---

.to_top {
  display: none; }

.footer {
  margin-top: 2rem;
  font-size: 0.8rem;
  font-style: italic;
  text-align: center;
  .footer_inner {
    p {
      padding: 0.8rem 0; } } }

// ---

blockquote.blockq-2m {
  padding-left: 40px !important;
  p {
    font-size: 1.05rem; } }

article.post_content aside {
  p, blockquote {
    font-size: 1.0rem; } }
